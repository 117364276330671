@font-face {
  font-family: 'Pacifico';
  src: local('Pacifico'), url(./fonts/Pacifico/Pacifico.ttf) format('truetype');
  font-weight: regular;
}

@font-face {
  font-family: 'Signika';
  src: local('Signika'), url(./fonts/Signika/Signika-Regular.otf) format('opentype');
  font-weight: regular;
}

/*@font-face {
  font-family: 'Signika';
  src: local('Signika'), url(./fonts/Signika/Signika-Bold.otf) format('otf');
  font-weight: Bold;
}

@font-face {
  font-family: 'Signika';
  src: local('Signika'), url(./fonts/Signika/Signika-Light.otf) format('otf');
  font-weight: Light;
}

@font-face {
  font-family: 'Signika';
  src: local('Signika'), url(./fonts/Signika/Signika-Semibold.otf) format('otf');
  font-weight: Semibold;
}
*/
body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.jumbotron {
  padding-top: 3rem;
  padding-bottom: 3rem;
  margin-bottom: 0;
  background-color: #fff;
}
@media (min-width: 768px) {
  .jumbotron {
    padding-top: 6rem;
    padding-bottom: 6rem;
  }
}

.jumbotron p:last-child {
  margin-bottom: 0;
}

.jumbotron-heading {
  font-weight: 300;
}

.jumbotron .container {
  max-width: 40rem;
}
