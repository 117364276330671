// Styling for the masthead
header.hero {
  position: relative;
  background-color: black;
  height: 85vh;
  min-height: 25rem;
  width: 100%;
  overflow: hidden;

  .intro-text {
    padding-top: 150px;
    padding-bottom: 100px;
    .intro-lead-in {
      font-size: 18px;
      line-height: 18px;
      margin-bottom: 25px;
      @include serif-font;
    }
    .intro-heading-lead {
      font-size: 25px;
      font-weight: 700;
      line-height: 25px;
      margin-bottom: 15px;
      @include heading-font;
    }
    .intro-heading {
      font-size: 50px;
      font-weight: 700;
      line-height: 50px;
      margin-bottom: 25px;
      @include heading-font;
    }
  }
}

header.hero video {
  position: absolute;
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  z-index: 0;
  -ms-transform: translateX(-50%) translateY(-50%);
  -moz-transform: translateX(-50%) translateY(-50%);
  -webkit-transform: translateX(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
}

header.hero .container {
  position: relative;
  z-index: 2;
}

header.hero .overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
  z-index: 1;
}

@media(min-width:768px) {
  header.hero {
    .intro-text {
      padding-top: 300px;
      padding-bottom: 200px;
      .intro-lead-in {
        font-size: 25px;
        line-height: 30px;
        margin-bottom: 50px;
        @include serif-font;
      }
      .intro-heading-lead {
        font-size: 35px;
        font-weight: 700;
        line-height: 45px;
        margin-bottom: 15px;
        @include heading-font;
      }
      .intro-heading {
        font-size: 60px;
        font-weight: 700;
        line-height: 75px;
        margin-bottom: 25px;
        @include heading-font;
      }
    }
  }
}
