// Override default variables before the import

// Core variables and mixins
@import "variables.scss";
@import "mixins.scss";
// Global CSS
@import "global.scss";
// Components
@import "navbar.scss";
@import "masthead.scss";
@import "footer.scss";

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';